@charset "UTF-8";
/**
 * Defines the main color of the shop theme. Usage: primary elements' background and color.
 */
/**
 * Defines alternative color of the shop theme. Usage: secondary elements' background and color.
 */
/**
 * Defines white color. Usage: not transparent elements' background of default background.
 */
/**
 * Defines a light shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines a lighter shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines the lightest shade color. Usage: elements' background, borders and text.
 */
/**
 * Defines the shade of the black color. Usage: as a base of black color for semi-transparent elements.
 */
/**
 * Defines a dark shade color. Usage: elements' text color variant.
 */
/**
 * Defines a darker shade color. Usage: elements' text color variant.
 */
/**
 * Defines the darkest shade color. Usage: elements' text color variant.
 */
/**
 * Defines a transparent color. Usage: elements' background, border.
 */
/**
 * Defines the main background color. Usage: body and side-drawer background.
 */
/**
 * Defines the main color of text. Usage: default global text color.
 */
/**
 * Defines a default global border color for the input elements.
 */
/**
 * Defines a default global border color for the input elements on focus event.
 */
/**
 * Defines a shadow shade and transparency for the shadowed elements.
 */
/**
 * Defines a default overlay color. Usage: elements with overlay - side-drawer, popups etc.
 */
/**
 * Defines a default image overlay color. Usage: images with overlay - lazy-image.
 */
/**
 * Defines action colors map.
 */
/**
 * Defines font family setting. Usage: body global font styles.
 */
/**
 * Defines font family setting for code. Can be used for code and pre elements.
 */
/**
 * Defines basic font size. Usage: global basic font size.
 */
/**
 * Font size values map. Usage: mixins providing styles for headings, text elements etc.
 */
/**
 * Font weight values map. Usage: mixin to set font weight property.
 */
/**
 * Font line height values map. Usage: mixins providing styles for headings, text elements etc.
 */
/**
 * Defines a default duration value for the animation effects. Usage: mixins providing animation properties.
 */
/**
 * Defines a default timing function for the animation effects. Usage: mixins providing animation properties.
 */
/**
 * Defines default z-index value.
 */
/**
 * Defines z-index value for the floating elements.
 */
/**
 * Defines z-index value for the notification area context.
 */
/**
 * Defines z-index value for the side drawer context.
 */
/**
 * Defines z-index value for the header context.
 */
/**
 * Defines z-index value for the search suggestions context.
 */
/**
 * Defines z-index value for the ajax loader.
 */
/**
 * Defines z-index value for the top navigation context.
 */
/**
 * Defines spacing values map. Usage: spacing helpers and mixins.
 */
/**
 * Defines breakpoints' values for responsive.
 */
/**
 * Defines grid system columns count.
 */
/**
 * Defines grid container max width.
 */
/**
 * Defines gutters' size for grid "with-gutter" modifier.
 */
/**
 * Defines import blacklist. Usage: import helper.
 */
/**
 * Provides list of properies for reset usage.
 */
/**
 * Wrapper function for the darken sass function with a default degree parameter.
 * @param {Color} $setting-color
 * @param {Number} $degree [10]
 */
/**
 * Wrapper function for the lighten sass function with a default degree parameter.
 * @param {Color} $setting-color
 * @param {Number} $degree [10]
 */
/**
 * Sets font-size property from font size settings map - $setting-font-size according to the passed map key.
 * @param {String | Number} $size
 */
/**
 * Sets font-weight property from font weight settings map - $setting-font-weight according to the passed map key.
 * @param {String} $weight
 */
/**
 * Sets line-height property from line-height settings map - $setting-font-line-height according to the passed map key.
 * @param {String | Number} $height
 */
/**
 * Povides transition property configured by the passed parameters.
 * @param {String} $props
 * @param {Number} $duration [$setting-effect-default-duration]
 * @param {String} $ease [$setting-effect-default-ease]
 */
/**
 * Forces element visibility.
 */
/**
 * Forces element invisibility.
 */
/**
 * Provides global class names with spacing properties set from $setting-spacing map.
 * @param {String} $name Main selector name.
 * @param {List} $sides [[]] List of sides to set properties.
 */
/**
 * Returns 0 or the first value from the $edges list.
 * @param {List} $edges [$setting-breakpoints] List length must be less than or equal to 2.
 * @return {Base}
 */
/**
 * Returns null or the last value from the $edges list.
 * @param {List} $edges [$setting-breakpoints] List length must be less than or equal to 2.
 * @return {Base}
 */
/**
 * Sets screen media query with min-width set to $min parameter.
 * @param $min {Number} [0]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with breakpoints range passed as parameters.
 * @param {Number} $min [0]
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with max-width set to $max parameter.
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Sets screen media query with breakpoints range passed to parameters or
 * with min-width query if only one parameter is passed.
 * @param {Number} $max [$setting-grid-max-width]
 * @content Extends mixin with properties for media query.
 */
/**
 * Returns column ratio in relation to columns count.
 * @param {Number} $column
 * @param {Number} $columns [$setting-grid-columns]
 * @return {Number}
 */
/**
 * Adds clearing for element with floated child elements.
 */
/**
 * Adds a box-shadow with basic values.
 */
/**
 * Checks parameter on presence in the blacklist of imports setting list.
 * @param {List} $keywords
 * @content Extends mixin with properties if $keywords is not on the blacklist.
 */
/*
    https://github.com/justinskolnick/unitize
    Copyright © 2012-2013 Justin Skolnick

    Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

    The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

    THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
/*
    https://github.com/justinskolnick/unitize
    Copyright © 2012-2013 Justin Skolnick

    Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:

    The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

    THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
/* stylelint-disable max-nesting-depth */
/* stylelint-enable max-nesting-depth */
/* stylelint-disable media-query-list-comma-newline-after,media-query-list-comma-space-after */
/* stylelint-disable declaration-no-important */
/**
 * Defines z-index value for the side drawer context.
 */
.summary-overview__grand-total-price {
  font-size: 1.1rem;
  line-height: 1.4; }

/**
 * @deprecated Use company-page-business-unit-chart-item mixin instead.
 */
/**
 * @deprecated Use company-user-agent-widget-company-user-list mixin instead.
 */
/**
 * @deprecated Use price-product-volume-widget-volume-price mixin instead.
 */
/**
 * @deprecated Use price-product-volume-widget-volume-price-table mixin instead.
 */
/**
 * @deprecated Use product-packaging-unit-widget-packaging-unit-cart mixin instead.
 */
/**
 * @deprecated Use product-search-widget-product-quick-add-form mixin instead.
 */
.collapsible-list__button {
  background-color: rgba(255, 255, 255, 0);
  color: #fecc00;
  cursor: pointer; }
  .collapsible-list__button:hover {
    text-decoration: underline; }
  .collapsible-list__button::after {
    content: attr(data-show); }
  .collapsible-list__button--shown::after {
    content: attr(data-hide); }

.collapsible-list__button-content {
  display: none; }

/* fe-sniffer:disabled correct-scss-component-structure */
/**
 * @deprecated Use shop-ui-quote-status-color mixin instead.
 */
/**
 * @deprecated Use tabs-widget-search-tabs mixin instead.
 */
.shopping-list-product-quick-add__container {
  padding: 0 1rem;
  background-color: #ececec; }

.shopping-list-product-quick-add__content {
  padding-bottom: 1rem; }

.shopping-list-product-quick-add__title {
  font-weight: 700;
  font-size: 1.06667rem; }

.shopping-list-product-quick-add__top-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 1rem 0; }
  @media only screen and (max-width: 767px) {
    .shopping-list-product-quick-add__top-bar {
      cursor: pointer; } }
  @media only screen and (min-width: 768px) {
    .shopping-list-product-quick-add__top-bar {
      pointer-events: none; } }
